<template>
  <div class="ReportsHeader innerFeedContent" :class="{ loading: $loading }">
    <Spacer num="1" />
    <div class="ReportsHeaderContent" dcard dradius>
      <div class="optionsHeader" left flex-center>
        <LargeButton :iconSize="iconSize" button primary dradius :label="labelReport" icon="file-lines" rightIcon="chevron-down" @click="menu" />
      </div>
      <div class="datePicker" right flex-center>
        <LargeButton
          :iconSize="iconSize"
          button
          dradius
          :label="$locale['from']"
          icon="calendars"
          rightIcon="chevron-down"
          v-calendar="{ date: Go.cutString(startDate), onDate: handleDate, name: 'startDate', onShorcut: handleShortcut }"
          :desc="`<span skeleton>${Go.cutString(startDate, 10)}</span>`"
        />
        <LargeButton
          :iconSize="iconSize"
          button
          dradius
          :label="$locale['to']"
          icon="calendars"
          rightIcon="chevron-down"
          v-calendar="{ date: Go.cutString(endDate), onDate: handleDate, name: 'endDate', onShorcut: handleShortcut }"
          :desc="`<span skeleton>${Go.cutString(endDate, 10)}</span>`"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    menu: function() {
      this.view({
        title: this.$locale["reports"],
        component: () => import("./ReportsMenu.vue"),
        class: "menu bottom",
        animation: "bottomIn",
        closeOutside: true,
      });
    },
    handleDate: function(date, { name }) {
      if (name === "startDate" && !this.$route.query.endDate) {
        this.setQuery({ [name]: date, period: "custom", endDate: Go.date(new Date(), "yyyy-mm-dd") });
        return;
      }

      this.setQuery({ [name]: date, period: "custom" });
    },
    handleShortcut: function(shorcut) {
      if (Go.is(shorcut, "Array")) {
        this.setQuery({ period: "custom", startDate: shorcut[0], endDate: shorcut[1] });
        return;
      }

      this.setQuery({ period: shorcut, startDate: undefined, endDate: undefined });
    },
  },
  computed: {
    labelReport: function() {
      return this.$locale[this.$route.query.report] || this.$locale["report_label"];
    },
    iconSize: function() {
      return Go.math(this.$mpadding, "*", 1.8);
    },
    statistics: function() {
      return this.$store.state.admin.salesByCreatorList.statistics || {};
    },
    startDate: function() {
      return this.$route.query.startDate || this.statistics.startDate;
    },
    endDate: function() {
      return this.$route.query.endDate || this.statistics.endDate;
    },
  },
};
</script>

<style lang="scss">
.ReportsHeader {
  .LargeButton[button] {
    display: inline-flex;
    width: auto;
  }

  .LargeButton[button][primary] {
    background-color: $primary_color;
    color: #fff;
  }

  .LargeButton[button] .LargeButtonRightIcon {
    padding: 0 0 0 $mpadding;
    margin: 0 0 0 $mpadding;
    border-left: solid 1px rgba(#000, 0.3);
  }

  .ReportsHeaderContent {
    padding: $mpadding/6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: $mpadding/4;
  }

  .ReportsHeaderContent [right] {
    background-color: rgba($color: #000000, $alpha: 0.08);
    border-radius: $mpadding;
  }

  .datePicker .LargeButton[button] {
    padding: $mpadding/2 $mpadding;
    text-align: left;
  }

  .datePicker .LargeButton[button] .LargeButtonDesc {
    margin: $mpadding/4 0 0 0;
    white-space: nowrap;
  }

  .optionsHeader {
    position: relative;
  }

  .optionsHeader,
  .datePicker {
    width: 100%;
  }

  @include screen($tablet_width) {
    .ReportsHeaderContent {
      flex-direction: row;
    }

    .optionsHeader,
    .datePicker {
      width: auto;
    }
  }
}
</style>
